import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loginv1',
  templateUrl: './loginv1.component.html',
  styleUrls: ['./loginv1.component.css']
})
export class Loginv1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
